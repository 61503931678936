<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Write the balanced equation for the reaction of
        <chemical-latex content="MnO4-" />
        with
        <chemical-latex content="H2C2O4" />
      </p>

      <p class="mb-3">
        <chemical-notation-input
          v-model="inputs.chemInput"
          class="ml-1"
          dense
          :show-note="false"
          :disabled="!allowEditing"
        />
      </p>

      <p class="mb-2">
        Fill in the table with the oxidation numbers of each atom in the reaction above.
      </p>
      <table class="mb-2 pl-6">
        <thead>
          <tr>
            <th></th>
            <th>Reactant</th>
            <th>Product</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <chemical-latex content="Mn" style="text-align: center" />
            </td>
            <td>
              <calculation-input
                v-model="inputs.input1"
                class="mr-1"
                style="max-width: 250px; display: inline-block"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.input5"
                class="mr-1"
                style="max-width: 250px; display: inline-block"
              />
            </td>
          </tr>

          <tr>
            <td>
              <chemical-latex content="O" style="text-align: center" />
            </td>
            <td>
              <calculation-input
                v-model="inputs.input2"
                class="mr-1"
                style="max-width: 250px; display: inline-block"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.input6"
                class="mr-1"
                style="max-width: 250px; display: inline-block"
              />
            </td>
          </tr>

          <tr>
            <td>
              <chemical-latex content="H" style="text-align: center" />
            </td>
            <td>
              <calculation-input
                v-model="inputs.input3"
                class="mr-1"
                style="max-width: 250px; display: inline-block"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.input7"
                class="mr-1"
                style="max-width: 250px; display: inline-block"
              />
            </td>
          </tr>

          <tr>
            <td>
              <chemical-latex content="C" style="text-align: center" />
            </td>
            <td>
              <calculation-input
                v-model="inputs.input4"
                class="mr-1"
                style="max-width: 250px; display: inline-block"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.input8"
                class="mr-1"
                style="max-width: 250px; display: inline-block"
              />
            </td>
          </tr>
        </tbody>
      </table>

      <p class="mb-3 pl-6">
        <b>Hint</b>. there are 2
        <chemical-latex content="C" />
        atoms in the reactant - each one loses 1 electron.
      </p>

      <p class="mb-2">Which species is oxidized?</p>
      <p class="mb-4">
        <v-text-field v-model="inputs.studentResponse1" outlined :disabled="!allowEditing" />
      </p>

      <p class="mb-2">Which is reduced?</p>
      <p class="mb-2">
        <v-text-field v-model="inputs.studentResponse2" outlined :disabled="!allowEditing" />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput';
import CalculationInput from '../inputs/CalculationInput.vue';
import ChemicalLatex from '../displayers/ChemicalLatex.vue';

export default {
  name: 'ChemCPP1220LSBQ1',
  components: {
    ChemicalNotationInput,
    CalculationInput,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        chemInput: null,
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
        input7: null,
        input8: null,
        studentResponse1: null,
        studentResponse2: null,
      },
    };
  },
};
</script>
<style scoped>
table {
  border-collapse: collapse;
}

td,
th {
  border-style: solid;
  border-width: 1px;
  padding: 10px;
}
</style>
